<template>
  <div class="pvhFactory">
    <basic-container>

      <el-dialog :title="$t('ADD')"
                 :visible.sync="addVisible"
                 fullscreen
                 :append-to-body="true">
        <el-form :model="addForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"
                 label-position="top">
          <el-collapse v-model="activeNames" @change="handleChange">

            <el-collapse-item :title="$t('Basic Data')" name="1">
              <el-col :span="12">
                <el-form-item :label="$t('MATERIAL CLASSIFICATION')" prop="classification">
                  <tree ref="tt_tree" style="width:100%" @getValue='value'
                        :placeholder="$t('PLEASE SELECT MATERIAL CLASSIFICATION')"
                        v-model="addForm.classification"
                        :props='defaultProps' :options='treeList'>
                    <el-option v-for="item in treeList"
                               :key="item.id"
                               :label="item.name"
                               :value="item.id">
                    </el-option>
                  </tree>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('MATERIAL NAME')" prop="name">
                  <el-input v-model="addForm.name"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('MATERIAL CODE')" prop="code">
                  <el-input v-model="addForm.code"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('SPECIFICATION AND MODEL')" prop="models">
                  <el-input v-model="addForm.models" type="number"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('UNIT OF MEASURE')" prop="unit">
                  <el-select style="width:100%" v-model="addForm.unitId"
                             :placeholder="$t('Pleaseselect')">
                    <el-option v-for="item in unitList"
                               :key="item.id"
                               :label="item.name"
                               :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('INVENTORY TYPE')" prop="type">
                  <el-select style="width:100%" v-model="addForm.type">
                    <el-option v-for="item in typeList"
                               :key="item.id"
                               :label="item.name"
                               :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item :label="$t('BAR CODE')" prop="barCode">
                  <el-input style="width:200%" v-model="addForm.barCode"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('NUMBER OF BRANCHES')" prop="branchNum">
                  <el-input-number style="width:20%" v-model="addForm.yarnCount"></el-input-number>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('DRAFTING MULTIPLE')" prop="draftingMultiple">
                  <el-select style="width:20%" v-model="addForm.draftingMultiple" @change="calculateGramWeight(addForm)"
                             :placeholder="$t('Pleaseselect')">
                    <el-option label="4.2" value=4.2></el-option>
                    <el-option label="4.0" value=4.0></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('GRAM WEIGHT')" prop="gramWeight">
                  <el-input style="width:20%" v-model="addForm.gramWeight" type="number"/>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('BASE PRODUCT PRICE')" prop="baseProductPrice">
                  <el-input style="width:20%" v-model="addForm.baseProductPrice" type="number"/>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('ACTUAL PRODUCT PRICE')" prop="actualProductPrice">
                  <el-input style="width:20%" v-model="addForm.actualProductPrice" type="number"/>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label-width="150px" :label="$t('ENABLE BATCH NO')" prop="isBatch">
                  <el-switch :active-value=1 :inactive-value=0 :active-text="$t('True')"
                             :inactive-text="$t('False')"
                             v-model="addForm.enableBatch"></el-switch>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label-width="150px" :label="$t('IS IT A STANDARD DISCOUNT VARIETY')"
                              prop="isProduct">
                  <el-switch :active-value=1 :inactive-value=0 :active-text="$t('True')"
                             :inactive-text="$t('False')"
                             v-model="addForm.isStandard"></el-switch>
                </el-form-item>
              </el-col>
            </el-collapse-item>


            <!--
            <el-collapse-item :title="$t('Material classification type attribute')" name="2">
                <el-col v-for="(item,index) in attributeList" :key="item.id" :span="12">
                    <el-form-item label-width="100px" :label=item.name>
                        <el-input-number v-if="item.type == 0" v-model="initList[index].value"
                                         :min="item.minValue"
                                         :max="item.maxValue"></el-input-number>
                        <el-select v-if="item.type == 1" v-model="initList[index].value">
                            <el-option v-for="(i,index) in (item.eunmList)"
                                       :key="index"
                                       :label="i"
                                       :value="i">
                            </el-option>
                        </el-select>
                        <el-input v-if="item.type == 2" v-model="initList[index].value"></el-input>
                    </el-form-item>
                </el-col>

            </el-collapse-item>
            -->
            <el-collapse-item v-if="categoryList" :title="$t('Material classification attribute')" name="2">
              <el-col v-for="(item,index) in categoryList" :key="item.id" :span="12">
                <el-form-item label-width="100px" :label=item.name>
                  <el-input-number v-if="item.type == 0" v-model="initAttributeList[index].value"
                                   :min="item.minValue"
                                   :max="item.maxValue"></el-input-number>
                  <el-select v-if="item.type == 1" v-model="initAttributeList[index].value">
                    <el-option v-for="(i,index) in item.productPropertyValueResponseList || [] "
                               :key="i.id"
                               :label="i.name"
                               :value="i.name">
                    </el-option>
                  </el-select>
                  <el-input v-if="item.type == 2" v-model="initAttributeList[index].value"></el-input>

                </el-form-item>
              </el-col>
            </el-collapse-item>

          </el-collapse>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="addVisible = false">{{ $t('CANCEL') }}</el-button>
          <el-button type="primary" @click="addSubmit">{{ $t('Yes') }}</el-button>
        </div>
      </el-dialog>

      <el-dialog :title="$t('Edit')"
                 :visible.sync="editVisible"
                 fullscreen
                 :append-to-body="true">
        <el-form :model="editForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"
                 label-position="top">
          <el-collapse v-model="activeNames" @change="handleChange">
            <el-collapse-item :title="$t('Basic Data')" name="1">
              <el-col :span="12">
                <el-form-item :label="$t('MATERIAL CLASSIFICATION')" prop="classification">
                  <tree style="width:100%" @getValue='value'
                        :placeholder="$t('PLEASE SELECT MATERIAL CLASSIFICATION')"
                        v-model="editForm.categoty"
                        :props='defaultProps' :options='treeList'>
                    <el-option v-for="item in treeList"
                               :key="item.id"
                               :label="item.name"
                               :value="item.id">
                    </el-option>
                  </tree>

                  <!--                  <el-cascader
                                        v-model="editForm.categoty"
                                        :options="treeList"></el-cascader>-->

                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('MATERIAL NAME')" prop="name">
                  <el-input v-model="editForm.name"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('MATERIAL CODE')" prop="code">
                  <el-input v-model="editForm.code"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('SPECIFICATION AND MODEL')" prop="models">
                  <el-input v-model="editForm.models"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('UNIT OF MEASURE')" prop="unit">
                  <el-select style="width:100%" v-model="editForm.unitId"
                             :placeholder="$t('Pleaseselect')">
                    <el-option v-for="item in unitList"
                               :key="item.id"
                               :label="item.name"
                               :value="item.id">
                    </el-option>
                  </el-select>
                  <!-- <el-input v-model="addForm.unit"></el-input> -->
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('INVENTORY TYPE')" prop="type">
                  <el-select style="width:100%" v-model="editForm.type">
                    <el-option v-for="item in typeList"
                               :key="item.id"
                               :label="item.name"
                               :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('BAR CODE')" prop="barCode">
                  <el-input v-model="editForm.barCode"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('NUMBER OF BRANCHES')" prop="branchNum">
                  <el-input-number v-model=editForm.yarnCount></el-input-number>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('DRAFTING MULTIPLE')" prop="draftingMultiple">
                  <el-select style="width:20%" v-model="editForm.draftingMultiple"
                             @change="calculateGramWeight(editForm)" :placeholder="$t('Pleaseselect')">
                    <el-option label="4.2" value=4.2></el-option>
                    <el-option label="4.0" value=4.0></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('GRAM WEIGHT')" prop="gramWeight">
                  <el-input style="width:20%" v-model="editForm.gramWeight" type="number"/>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('BASE PRODUCT PRICE')" prop="baseProductPrice">
                  <el-input style="width:20%" v-model="editForm.baseProductPrice" type="number"/>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('ACTUAL PRODUCT PRICE')" prop="actualProductPrice">
                  <el-input style="width:20%" v-model="editForm.actualProductPrice" type="number"/>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label-width="150px" :label="$t('ENABLE BATCH NO')" prop="isBatch">
                  <el-switch :active-value=1 :inactive-value=0 :active-text="$t('True')"
                             :inactive-text="$t('False')"
                             v-model="editForm.enableBatch"></el-switch>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label-width="150px" :label="$t('IS IT A STANDARD DISCOUNT VARIETY')"
                              prop="isProduct">
                  <el-switch :active-value=1 :inactive-value=0 :active-text="$t('True')"
                             :inactive-text="$t('False')"
                             v-model="editForm.isStandard"></el-switch>
                </el-form-item>
              </el-col>
            </el-collapse-item>


            <!--            <el-collapse-item :title="$t('Material classification type attribute')" name="2">-->
            <!--              <el-col v-for="(item) in editTypeList" :key="item.id" :span="12">-->
            <!--                <el-form-item label-width="100px" :label=item.name>-->
            <!--                  <el-input-number v-if="item.type == 0" v-model="item.value" :min="item.minValue"-->
            <!--                                   :max="item.maxValue"></el-input-number>-->
            <!--                  <el-select v-if="item.type == 1" v-model="item.value">-->
            <!--                    <el-option v-for="(i,index) in (item.eunmList)"-->
            <!--                               :key="index"-->
            <!--                               :label="i"-->
            <!--                               :value="i">-->
            <!--                    </el-option>-->
            <!--                  </el-select>-->
            <!--                  <el-input v-if="item.type == 2" v-model="item.value"></el-input>-->

            <!--                </el-form-item>-->
            <!--              </el-col>-->
            <!--            </el-collapse-item>-->


            <el-collapse-item :title="$t('Material classification attribute')" name="3">
              <el-col v-for="(item) in editClassificationList" :key="item.id" :span="12">
                <el-form-item label-width="100px" :label=item.name>
                  <el-input-number v-if="item.type == 0" v-model="item.value" :min="item.minValue"
                                   :max="item.maxValue"></el-input-number>
                  <el-select v-if="item.type == 1" v-model="item.value">
                    <el-option v-for="(i,index) in (item.productPropertyValueResponseList || [])"
                               :key="i.id"
                               :label="i.name"
                               :value="i.name">
                    </el-option>
                  </el-select>
                  <el-input v-if="item.type == 2" v-model="item.value"></el-input>
                </el-form-item>
              </el-col>
            </el-collapse-item>
          </el-collapse>


          <!-- <el-form-item  :label="$t('INVENTORY TYPE')" prop="type">
            <el-select v-model="addForm.type" clearable>
              <el-option v-for="item in inventoryTypeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item> -->
          <!-- <el-form-item  :label="$t('CLASSIFICATION VALUE')" prop="scaleFactor">
            <el-input v-model="addForm.scaleFactor"></el-input>
          </el-form-item> -->
          <!-- <el-form-item  :label="$t('Material Properties')" prop="properties">
            <el-select v-model="addForm.propertieList" multiple :placeholder="$t('Pleaseselect')">
              <el-option
                  v-for="item in properties"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item> -->
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="editVisible = false">{{ $t('CANCEL') }}</el-button>
          <el-button type="primary" @click="editSubmit">{{ $t('Yes') }}</el-button>
        </div>
      </el-dialog>

      <avue-crud :option="tableOption"
                 :data="tableData"
                 :table-loading="tableLoading"
                 :page.sync="page"
                 ref="crud"
                 @size-change="sizeChange"
                 @current-change="currentChange"
                 @selection-change="selectionChange"
                 @refresh-change="refreshChange"
                 @before-open="beforeOpen"
                 @row-update="handleUpdate"
                 @row-del="handleDel">
        <template slot="menuLeft" style="width: calc(100% - 80px);">
          <el-row :gutter="20" class="topFromSearch">
            <el-col :xs="24" :sm="16" :md="16" :lg="19" :xl="20" class="fromOutDiv">
              <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                <span>{{ $t('MATERIAL NAME OR CODE') }}:</span>
                <el-input v-model="nameOrCode" @focus="focusDiv($event)"
                          @blur="blurDiv($event)"></el-input>
              </el-col>
            </el-col>
            <el-col :xs="24" :sm="8" :md="8" :lg="5" :xl="4"
                    style="padding-top: 10px; text-align: right; align-self: flex-end;">
              <el-button type="primary" icon="iconfont tianjia" size="mini" :title="$t('New')" plain
                         @click="addShow"> {{ $t('New') }}
              </el-button>
              <el-button :title="$t('Refresh')" icon="iconfont shuaxin" size="mini" plain
                         @click="refreshChange"> {{ $t('Refresh') }}
              </el-button>
            </el-col>
          </el-row>
        </template>
        <template slot="status" slot-scope="scope">
          <el-switch v-model="scope.row.status" :inactive-value="1" :active-value="0"
                     @change="change(scope.row)">
          </el-switch>
        </template>
        <template slot="menu" scope="scope">
          <div v-if="scope.row.status==0" style="height:30px;"/>
        </template>
        <template slot="menu" scope="scope">
          <el-button type="text"
                     size="small"
                     icon="el-icon-edit"
                     class="none-border"
                     @click.stop="editShow(scope.row)">
            {{ $t("Edit") }}
          </el-button>
          <el-button type="text"
                     size="small"
                     icon="el-icon-delete"
                     class="none-border"
                     @click.stop="handleDel(scope.row)">
            {{ $t("Delete") }}
          </el-button>
          <el-button type="text"
                     size="small"
                     icon="el-icon-s-order"
                     class="none-border"
                     @click.stop="clickProductPrice(scope.row)">
            {{ $t("Price") }}
          </el-button>
          <el-button type="text"
                     size="small"
                     icon="el-icon-aim"
                     class="none-border"
                     @click.stop="compareProductQuality(scope.row)">
            {{ $t("Quality") }}
          </el-button>
        </template>
        <template slot-scope="{row,index,type}" slot="menuForm">
          <el-button type="primary" icon="el-icon-circle-plus-outline" size="small" v-if="type=='add'"
                     @click="$refs.crud.rowSave()">
            {{ $t("Hold") }}
          </el-button>
          <el-button type="primary" icon="el-icon-circle-check" size="small" v-if="type=='edit'"
                     @click="$refs.crud.rowUpdate()">
            {{ $t("Hold") }}
          </el-button>
          <el-button icon="el-icon-circle-close" size="small" @click="$refs.crud.closeDialog()">
            {{ $t("Cancel") }}
          </el-button>
        </template>
      </avue-crud>
    </basic-container>
  </div>
</template>
<script>
import {
  list,
  add,
  edit,
  remove,
  setStatus,
  audit,
  getType,
  getIdType,
  getItemByProductId,
  getGramWeight,
  getProductCategoryDate
} from "@/api/mtrD";
import {getMtrType} from "@/api/config";
import tree from '@/components/tree/tree.vue'

export default {
  components: {tree},
  watch: {
    mtrId: function (value) {
      console.log(value)
      // value ? this.tableOption.addBtn = true : "";
      this.tableOption.column[0].value = value;
    },
    'addForm.unitId'(New) {
      console.log(New)
    },
    addVisible(New) {
      this.$refs['ruleForm'].resetFields();
      this.addForm.classification = ''
      this.editForm.classification = ''
      this.editForm.addForm.unitId = ''
      this.addForm.addForm.unitId = ''
      if (New) {
        /*    this.editForm = {
          id: null,
              name: null,
              code:null,
              models: null,
              unitId: null,
              type:null,
              barCode: null,
              yarnCount: 0,
              enableBatch: 0,
              isStandard: 0,
          classification: ''
        }
        this. addForm = {
          name: null,
              code:null,
              models: null,
              unitId: null,
              type:null,
              barCode: null,
              yarnCount: 0,
              enableBatch: null,
              isStandard: null,
          classification: ''
        }*/
      }
    }
  },
  data() {
    return {
      //字典id
      // 新增
      addForm: {
        name: null,
        code: null,
        models: null,
        unitId: null,
        type: null,
        barCode: null,
        yarnCount: 0,
        draftingMultiple: null,
        gramWeight: null,
        enableBatch: null,
        isStandard: null,
        classification: '',
        actualProductPrice: '',
        baseProductPrice: '',
      },
      rules: {
        classification: [
          {required: true, message: this.$t('PLEASE SELECT MATERIAL CLASSIFICATION'), trigger: 'blur'},
        ],
        name: [
          {required: true, message: this.$t('PLEASE SELECT A MATERIAL NAME'), trigger: 'blur'},
        ],
        code: [
          {required: true, message: this.$t('PLEASE ENTER THE MATERIAL CODE'), trigger: 'blur'},
        ],
        // models: [
        //   {required: true, message: this.$t('PLEASE SELECT SPECIFICATION AND MODEL'), trigger: 'blur'},
        // ],
        branchNum: [
          {required: true, message: this.$t('PLEASE ENTER THE NUMBER OF PIECES'), trigger: 'blur'},
        ],
        isBatch: [
          {required: true, message: this.$t('PLEASE ENTER THE ACTIVATION BATCH NUMBER'), trigger: 'blur'},
        ],
        isProduct: [
          {
            required: true,
            message: this.$t('PLEASE ENTER WHETHER IT IS A STANDARD DISCOUNT VARIETY'),
            trigger: 'blur'
          },
        ]
      },
      defaultProps: {
        label: "name",
        value: "id",
        children: "ChildNodes"
      },
      addVisible: false,
      unitList: [],
      typeList: [],
      categoryList: [],
      attributeList: [],
      initList: [],
      initAttributeList: [],
      activeNames: ['1', '2', '3'],
      draftData: ['4.2', '4.0'],
      // 编辑
      editVisible: false,
      editForm: {
        id: null,
        name: null,
        code: null,
        models: null,
        unitId: null,
        type: null,
        barCode: null,
        yarnCount: 0,
        draftingMultiple: null,
        gramWeight: null,
        enableBatch: 0,
        isStandard: 0,
        classification: '',
        actualProductPrice: '',
        baseProductPrice: ''
      },
      editTypeList: [],
      editClassificationList: [],
      getProductId: '', //品种ID
      productList: [],
      dafV: "",
      typeId: '',
      tableLoading: false,
      mtrDIcId: "",
      scaleFactor: '',
      selectionList: [],
      mtrId: 1,
      nameOrCode: "",
      status: "",
      treeList: [],
      treeList1: [],
      page: {
        pageSize: 10,
        pagerCount: 5,
        total: 0,
        pageCurrent: 1
      },
      disabled: true,
      tableData: [],
      tableOption: {
        addBtn: false,
        delBtn: false,
        editBtn: false,
        refreshBtn: false,
        header: true,
        menuWidth: 200,
        selection: true,
        tip: false,
        columnBtn: false,
        labelWidth: 150,
        dialogFullscreen: true,
        // addBtnText: this.$t('ADD'),
        delBtnText: this.$t('DELETE'),
        editBtnText: this.$t('Edit'),
        emptyText: this.$t('No Data'),
        menuTitle: this.$t('OPERATION'),
        editTitle: this.$t('Edit'),
        // addTitle: this.$t('Add'),
        saveBtn: false,
        updateBtn: false,
        cancelBtn: false,
        column: [{
          label: this.$t('MATERIAL CLASSIFICATION'),
          editDisplay: false,
          hide: true,
          type: "tree",
          value: "",
          change: (value) => {
            console.log(value);
            this.getProductId = value.value
            if (this.getProductId) {
              getItemByProductId(this.getProductId).then(res => {
                res.data.data.forEach((item, index) => {
                  console.log(item);
                  this.productList.push({productPropertyItemId: item.id, value: ''})
                  console.log(this.productList);
                  if (item.type == 0) {
                    let obj = {
                      label: item.name,
                      type: "number",
                      prop: index
                    }
                    this.tableOption.column.push(obj)
                    console.log(obj);
                  } else if (item.type == 1) {
                    let obj = {
                      label: item.name,
                      type: "select",
                    }
                    this.tableOption.column.push(obj)
                    console.log(obj);
                  } else {
                    let obj = {
                      label: item.name,
                      type: "input",
                    }
                    this.tableOption.column.push(obj)
                    console.log(obj);
                  }
                })
              })
            }
          },
          dicData: [],
          //dicUrl: this.businessUrl + "productCategory/select",
          // change: function ($value) {
          //     console.log($value)
          //     getIdType($value.value).then(res => {
          //         console.log($value)
          //         console.log(res, "256")
          //         this.mtrDIcId = res.data.data.type;
          //         //this.$refs.crud
          //         this.$refs.crud.$refs.dialogForm.$refs.tableForm.form.type = res.data.data.type;
          //     });
          //     if ($value) {
          //         //this.tableOption.column[8].addDisplay = false;
          //         //this.tableOption.column[10].addDisplay = false;
          //     } else {
          //         //this.tableOption.column[8].addDisplay = true;
          //         //this.tableOption.column[10].addDisplay = true;
          //     }
          // }.bind(this),
          rules: [{
            required: true,
            message: this.$t('PLEASE SELECT MATERIAL CLASSIFICATION'),
            trigger: "blur"
          }],
          prop: "tree",
          props: {
            label: "name",
            value: "id",
            children: "ChildNodes"
          },
          row: true,
          span: 24
        }, {
          label: this.$t('MATERIAL NAME'),
          placeholder: this.$t('PLEASE ENTER MATERIAL NAME'),
          rules: [{
            required: true,
            message: this.$t('PLEASE SELECT A MATERIAL NAME'),
            trigger: "blur"
          }],
          prop: "name"
        }, {
          label: this.$t('MATERIAL CODE'),
          placeholder: this.$t('PLEASE ENTER THE MATERIAL CODE'),
          rules: [{
            required: true,
            message: this.$t('PLEASE SELECT MATERIAL CODE'),
            trigger: "blur"
          }],
          prop: "code"
        }, {
          label: this.$t('SPECIFICATION AND MODEL'),
          placeholder: this.$t('PRODUCT SPECIFICATION AND MODEL'),
          prop: "models",
          rules: [{
            required: false,
            message: this.$t('PLEASE SELECT SPECIFICATION AND MODEL'),
            trigger: "blur"
          }]
        }, {
          label: this.$t('UNIT OF MEASURE'),
          placeholder: this.$t('PLEASE SELECT UOM'),
          dicUrl: this.businessUrl + "unit/selectAllUnit",
          type: "select",
          props: {
            label: "name",
            value: "id"
          },
          prop: "unitId",
          // width: 80,
        }, {
          label: this.$t('INVENTORY TYPE'),
          placeholder: this.$t('PLEASE SELECT INVENTORY TYPE'),
          disabled: false,
          // hide: true,
          type: "select",
          prop: "type",
          dicUrl: this.businessUrl + "dict/selectDictByCategoryId?categoryId=inventoryType",
          // change: function (value) {
          //   console.log(value.value);
          // },
          formatter: function (row) {
            // console.log(row, value);
            return row.strType;
          },
          props: {
            label: "name",
            value: "id"
          },
          rules: [{
            required: false,
            message: this.$t('PLEASE SELECT INVENTORY TYPE'),
            trigger: "blur"
          }]
        },
          {
            label: this.$t('BAR CODE'),
            placeholder: this.$t('PLEASE ENTER BARCODE'),
            prop: "barCode",
            // width: 80,
          },
          // {
          //     label: this.$t('RAW MATERIAL COMPOSITION'),
          //     placeholder: this.$t('PLEASE SELECT RAW MATERIAL COMPOSITION'),
          //     multiple: true,
          //     addDisplay: true,
          //     width: 120,
          //     type: "select",
          //     dicData: [],
          //     props: {
          //         label: "productPropertyValueName",
          //         value: "id"
          //     },
          //     prop: "productPropertyValueList1"
          // }, {
          //     label: this.$t('SPINNING PROCESS'),
          //     placeholder: this.$t('PLEASE SELECT SPINNING PROCESS'),
          //     type: "select",
          //     multiple: true,
          //     dicData: [],
          //     dicUrl: this.businessUrl + "productPropertyItem/queryProperty?name=纺纱工艺",
          //     props: {
          //         label: "productPropertyValueName",
          //         value: "id"
          //     },
          //     prop: "productPropertyValueList2"
          // }, {
          //     label: this.$t('YARN USE'),
          //     placeholder: this.$t('PLEASE SELECT YARN USAGE'),
          //     type: "select",
          //     multiple: true,
          //     dicData: [],
          //     props: {
          //         label: "productPropertyValueName",
          //         value: "id"
          //     },
          //     prop: "productPropertyValueList3"
          // },
          {
            label: this.$t('NUMBER OF BRANCHES'),
            value: 0,
            type: "number",
            prop: "yarnCount",
            rules: [{
              required: true,
              message: this.$t('PLEASE ENTER THE NUMBER OF PIECES'),
              trigger: "blur"
            }],
            // width:100,
          }, {
            label: this.$t('ENABLE BATCH NO'),
            width: 120,
            value: 0,
            prop: "enableBatch",
            type: "radio",
            dicData: [{
              label: this.$t('True'),
              value: 1
            }, {
              label: this.$t('False'),
              value: 0
            }],
            rules: [{
              required: true,
              message: this.$t('PLEASE ENTER THE ACTIVATION BATCH NUMBER'),
              trigger: "blur"
            }]
          }, {
            label: this.$t('IS IT A STANDARD DISCOUNT VARIETY'),
            width: 120,
            value: 0,
            prop: "isStandard",
            type: "radio",
            dicData: [{
              label: this.$t('True'),
              value: 1
            }, {
              label: this.$t('False'),
              value: 0
            }],
            rules: [{
              required: true,
              message: this.$t('PLEASE ENTER WHETHER IT IS A STANDARD DISCOUNT VARIETY'),
              trigger: "blur"
            }]
          }, {
            label: this.$t('STATE'),
            addDisplay: false,
            prop: "status",
            type: "select",
            slot: true,
            dicData: [{
              label: this.$t('ENABLE'),
              value: 0
            }, {
              label: this.$t('DISABLE'),
              value: 1
            }]
          }, {
            label: this.$t('CREATTIME'),
            width: 150,
            editDisplay: false,
            addDisplay: false,
            prop: "createTime"
          }]
      }
    }
  },
  created() {
    this.request.get(this.businessUrl + "unit/selectAllUnit").then(res => {
      console.log(res);
      this.unitList = res.data.data
    });
    this.request.get(this.businessUrl + "dict/selectDictByCategoryId?categoryId=inventoryType").then(res => {
      console.log(res);
      this.typeList = res.data.data
    });
    this.request.get(this.businessUrl + "productCategory/select").then(res => {
      this.treeList = res.data.data
      this.treeList1 = JSON.stringify(res.data.data).replace(/id/gi, 'value');
      this.treeList1 = this.treeList1.replace(/name/gi, 'label');
      this.treeList1 = this.treeList1.replace(/ChildNodes/gi, 'children');
      this.treeList1 = JSON.parse(this.treeList1);
    });
    list()
  },
  methods: {
    focusDiv(obj) {//搜索框获取焦点
      if (obj.currentTarget === undefined) {
        obj.$el.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
      } else {
        obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
      }
    },//搜索框失去焦点
    blurDiv(obj) {
      if (obj.currentTarget === undefined) {
        if (obj.value === "") {
          obj.$el.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
        }
      } else {
        if (obj.target.value == "") {
          obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
        }
      }

    },//表格行样式
    addShow() {
      this.addVisible = true;
    },
    //计算定量克重
    calculateGramWeight(addForm) {
      getGramWeight(this.scaleFactor, addForm).then(res => {
        this.addForm.gramWeight = res.data.data.gramWeight
        this.editForm.gramWeight = res.data.data.gramWeight
      });
    },
    //add submit
    addSubmit() {
      let list = this.initList.concat(this.initAttributeList)

      add(this.addForm, list, this.getProductId).then((res) => {
        if (res.data.code == "0000") {
          this.$message({
            type: "success",
            message: this.$t('AddSuccess')
          });

          this.list();
          this.$nextTick(() => {
            this.$refs.tt_tree.clearHandle()
            this.addForm = this.$options.data.call(this).addForm
          })
          this.categoryList = []
          this.initAttributeList = []
          this.addVisible = false
        }
      });

    },
    handleChange(val) {
      console.log(val);
    },
    //物料分类selected
    value(e) {
      console.log(e)
      if (!e) {
        return ''
      }
      this.initList = [];
      this.initAttributeList = [];
      this.getProductId = e;

      //edit
      this.editForm.categoty = e;

      getItemByProductId(this.getProductId).then(res => {
        this.$nextTick(() => {
          //add
          this.categoryList = res.data.data;
          this.categoryList.forEach((item) => {
            this.initAttributeList.push({
              productPropertyItemId: item.id,
              value: ''
            })
          })
          //edit
          this.editClassificationList = res.data.data;
          console.log(this.editClassificationList)
        })
        getProductCategoryDate(e).then(res => {
          this.scaleFactor = res.data.data.scaleFactor
        })
      })
    },
    //查看物料的价格明细
    clickProductPrice(item) {
      this.$router.push({path: '/stealth/productPrice', query: {productId: item.id}})
    },
    //对比物料的质量
    compareProductQuality(item) {
      this.$router.push({
        path: '/quality/materialComparisonAdd',
        query: {productId: item.id, productCode: item.code, productName: item.name}
      })
    },
    //  编辑
    editShow(row) {
      //跳转新页面
      // this.$router.push({
      //   path: '/mtr/materialsaddedit',
      //   query:{
      //      id:row.id
      //   }
      // })
      console.log(row.productPropertyItemList)
      this.editTypeList = [];
      let editClassificationList = [];
      this.editForm.categoty = row.categoty
      this.editForm.id = row.id
      this.editForm.name = row.name;
      this.editForm.code = row.code;
      this.editForm.models = row.models;
      this.editForm.type = row.type;
      this.editForm.barCode = row.barCode;
      this.editForm.unitId = row.unitId;
      this.editForm.yarnCount = row.yarnCount;
      this.editForm.enableBatch = row.enableBatch;
      this.editForm.isStandard = row.isStandard;
      this.editForm.draftingMultiple = row.draftingMultiple;
      this.editForm.gramWeight = row.gramWeight;
      this.editForm.actualProductPrice = row.price;
      this.editForm.baseProductPrice = row.basePrice;
      if (row.productPropertyItemList) {
        row.productPropertyItemList.forEach(item => {
          if (item.flag == 0) {
            this.editTypeList.push(item)
          } else {
            editClassificationList.push(item);
            this.editClassificationList = editClassificationList;
          }
        })
      }
      this.editVisible = true;
      getProductCategoryDate(this.editForm.categoty).then(res => {
        this.scaleFactor = res.data.data.scaleFactor
      })
    },
    editSubmit() {
      let list = this.editTypeList.concat(this.editClassificationList)
      console.log(list);
      let tmp = []
      list.forEach((item) => {
        tmp.push({
          productPropertyItemId: item.id,
          value: item.value
        })
      })
      edit(this.editForm, tmp).then((res) => {
        console.log(res);
        if (res.data.code == "0000") {
          this.$message({
            type: "success",
            message: this.$t('EDIT SUCCEEDED')
          });
        }
        this.list();
        this.editVisible = false
      })
    },
    beforeOpen() {

    },
    setAudit(status) {
      audit(this.selectionList[0].id, status).then(() => {
        if (status == 0) {
          this.$message({type: "success", message: this.$t('AUDIT SUCCEEDED')});
        } else {
          this.$message({type: "success", message: this.$t('DE APPROVAL SUCCEEDED')});
        }

      })
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
      this.list();
    },
    currentChange(pageCurrent) {
      this.page.pageCurrent = pageCurrent;
      this.list();
    },
    clickList(mtrId) {
      let str;
      if (mtrId == 2) {
        str = 'unclassified';
      } else {
        str = mtrId;
      }
      this.mtrId = str;
      console.log(this.mtrId);
      list(this.status, this.nameOrCode, this.page.pageCurrent, this.page.pageSize, str).then(res => {
        res.data.data.items.forEach(v => {
          v.productPropertyItemList ? v.productPropertyItemList.forEach(value => {
            if (value.name == "纺纱工艺") {
              v.productPropertyValueList2 = value.id;
            } else if (value.name == "原料成分") {
              v.productPropertyValueList1 = value.id;
            } else if (value.name == "纱线用途") {
              v.productPropertyValueList3 = value.id;
            }
          }) : "";
          v.productAddition ? v.yarnCount = v.productAddition.yarnCount : "";
        })
        this.tableData = res.data.data.items;
        this.page.total = res.data.data.total;
      })
    },
    handleDel(row) {
      this.$confirm(this.$t('IsDel'), this.$t('Tips'), {
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('Cancel'),
        type: 'warning'
      }).then(() => {
        remove(row.id).then((res) => {
          if (res.data.code == "0000") {
            this.$message({
              type: "success",
              message: this.$t('DeleteSuccess'),
            });
          }
          this.list();
        })
      })
    },
    handleUpdate(row, index, done) {
      // if (row.productPropertyValueList1 && row.productPropertyValueList2 && row.productPropertyValueList3) {
      //   saveList.push({
      //     productPropertyItemId : this.tableOption.column[8].dicData.find(value => {
      //     return value.id == row.productPropertyValueList1;
      //   }).productPropertyValueId,
      //     id : row.productPropertyValueList1});
      //     console.log(this.tableOption.column[9].dicData,row.productPropertyValueList2);
      //   saveList.push({
      //     productPropertyItemId : this.tableOption.column[9].dicData.find(value => {
      //     return value.id == row.productPropertyValueList2;
      //   }).productPropertyValueId,
      //     id : row.productPropertyValueList2});
      //     saveList.push({
      //     productPropertyItemId : this.tableOption.column[10].dicData.find(value => {
      //     return value.id == row.productPropertyValueList3;
      //   }).productPropertyValueId,
      //     id : row.productPropertyValueList3});
      //   console.log(saveList);
      //   row.productPropertyValueList = saveList;
      // }
      console.log(row)
      edit(row, this.mtrId).then((res) => {
        console.log(res);
        if (res.data.code == "0000") {
          this.$message({
            type: "success",
            message: this.$t('EDIT SUCCEEDED')
          });
        }
        this.list();
        done();
      })
    },
    handleSave(row, done) {
      console.log(row);
      // let saveList = [];
      // if (row.productPropertyValueList1 && row.productPropertyValueList2 && row.productPropertyValueList3) {
      //   row.productPropertyValueList1.forEach((v,i)=>{
      //     saveList.push({
      //       productPropertyItemId : this.tableOption.column[8].dicData.find(value => {
      //         return value.id == row.productPropertyValueList1[0];
      //       }).productPropertyValueId,
      //         id : row.productPropertyValueList1[0]});
      //     })
      //   row.productPropertyValueList2.forEach((v,i)=>{
      //     saveList.push({
      //       productPropertyItemId : this.tableOption.column[9].dicData.find(value => {
      //       return value.id == row.productPropertyValueList2[i];
      //     }).productPropertyValueId,
      //       id : row.productPropertyValueList2[i]});
      //   })
      //   row.productPropertyValueList3.forEach((v,i)=>{
      //     saveList.push({
      //       productPropertyItemId : this.tableOption.column[10].dicData.find(value => {
      //       return value.id == row.productPropertyValueList3[i];
      //     }).productPropertyValueId,
      //       id : row.productPropertyValueList3[i]});
      //   })
      //   console.log(saveList);
      //   row.productPropertyValueList = saveList;
      // }
      // delete row.productPropertyValueList1;
      // delete row.productPropertyValueList2;
      // delete row.productPropertyValueList3;
      // delete row.productAdditionDto;
      // row.type = this.mtrDIcId;
      // console.log(this.mtrId)
      // console.log(row)
      let str
      if (this.mtrId == 2) {
        str = 'unclassified'
      } else {
        str = this.mtrId
      }
      add(row, str).then((res) => {
        if (res.data.code == "0000") {
          this.$message({
            type: "success",
            message: this.$t('AddSuccess')
          });
        }
        this.list();
        done();
      });
    },
    change(row) {
      setStatus(row).then(() => {
        this.$message({
          type: "success",
          message: this.$t('STATUS MODIFIED SUCCESSFULLY')
        });
        this.list();
      })
    },
    selectionChange(list) {
      this.selectionList = list;
      console.log(list);
      list != "" ? this.disabled = false : this.disabled = true;
    },
    refreshChange() {
      this.list();
    },
    list() {
      list(this.status, this.nameOrCode, this.page.pageCurrent, this.page.pageSize, this.mtrId).then(res => {
        console.log(res);
        // res.data.data.items.forEach(v => {
        //   v.productPropertyItemList.forEach(value => {
        //     if(value.name == "纺纱工艺") {
        //       v.productPropertyValueList2 = value.id;
        //     } else if(value.name == "原料成分") {
        //       v.productPropertyValueList1 = value.id;
        //     } else if(value.name == "纱线用途") {
        //       v.productPropertyValueList3 = value.id;
        //     }
        //   });
        //   v.productAddition ? v.yarnCount = v.productAddition.yarnCount : "";
        // })
        if (res.data.data == null) {
          this.tableData = [];
          this.page.total = 0;
        } else {
          this.tableData = res.data.data.items;
          this.page.total = res.data.data.total;
        }

      })
    },
    envText: function () {
      return this.env
    }
  }
}
</script>
<style scoped>
.el-select-dropdown__item {
  padding-left: 0px;
}
</style>
