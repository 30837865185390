var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pvhFactory" },
    [
      _c(
        "basic-container",
        [
          _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.$t("ADD"),
                visible: _vm.addVisible,
                fullscreen: "",
                "append-to-body": true
              },
              on: {
                "update:visible": function($event) {
                  _vm.addVisible = $event
                }
              }
            },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.addForm,
                    rules: _vm.rules,
                    "label-width": "100px",
                    "label-position": "top"
                  }
                },
                [
                  _c(
                    "el-collapse",
                    {
                      on: { change: _vm.handleChange },
                      model: {
                        value: _vm.activeNames,
                        callback: function($$v) {
                          _vm.activeNames = $$v
                        },
                        expression: "activeNames"
                      }
                    },
                    [
                      _c(
                        "el-collapse-item",
                        { attrs: { title: _vm.$t("Basic Data"), name: "1" } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("MATERIAL CLASSIFICATION"),
                                    prop: "classification"
                                  }
                                },
                                [
                                  _c(
                                    "tree",
                                    {
                                      ref: "tt_tree",
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "PLEASE SELECT MATERIAL CLASSIFICATION"
                                        ),
                                        props: _vm.defaultProps,
                                        options: _vm.treeList
                                      },
                                      on: { getValue: _vm.value },
                                      model: {
                                        value: _vm.addForm.classification,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.addForm,
                                            "classification",
                                            $$v
                                          )
                                        },
                                        expression: "addForm.classification"
                                      }
                                    },
                                    _vm._l(_vm.treeList, function(item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("MATERIAL NAME"),
                                    prop: "name"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.addForm.name,
                                      callback: function($$v) {
                                        _vm.$set(_vm.addForm, "name", $$v)
                                      },
                                      expression: "addForm.name"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("MATERIAL CODE"),
                                    prop: "code"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.addForm.code,
                                      callback: function($$v) {
                                        _vm.$set(_vm.addForm, "code", $$v)
                                      },
                                      expression: "addForm.code"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("SPECIFICATION AND MODEL"),
                                    prop: "models"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { type: "number" },
                                    model: {
                                      value: _vm.addForm.models,
                                      callback: function($$v) {
                                        _vm.$set(_vm.addForm, "models", $$v)
                                      },
                                      expression: "addForm.models"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("UNIT OF MEASURE"),
                                    prop: "unit"
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        placeholder: _vm.$t("Pleaseselect")
                                      },
                                      model: {
                                        value: _vm.addForm.unitId,
                                        callback: function($$v) {
                                          _vm.$set(_vm.addForm, "unitId", $$v)
                                        },
                                        expression: "addForm.unitId"
                                      }
                                    },
                                    _vm._l(_vm.unitList, function(item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("INVENTORY TYPE"),
                                    prop: "type"
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      model: {
                                        value: _vm.addForm.type,
                                        callback: function($$v) {
                                          _vm.$set(_vm.addForm, "type", $$v)
                                        },
                                        expression: "addForm.type"
                                      }
                                    },
                                    _vm._l(_vm.typeList, function(item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("BAR CODE"),
                                    prop: "barCode"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "200%" },
                                    model: {
                                      value: _vm.addForm.barCode,
                                      callback: function($$v) {
                                        _vm.$set(_vm.addForm, "barCode", $$v)
                                      },
                                      expression: "addForm.barCode"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("NUMBER OF BRANCHES"),
                                    prop: "branchNum"
                                  }
                                },
                                [
                                  _c("el-input-number", {
                                    staticStyle: { width: "20%" },
                                    model: {
                                      value: _vm.addForm.yarnCount,
                                      callback: function($$v) {
                                        _vm.$set(_vm.addForm, "yarnCount", $$v)
                                      },
                                      expression: "addForm.yarnCount"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("DRAFTING MULTIPLE"),
                                    prop: "draftingMultiple"
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "20%" },
                                      attrs: {
                                        placeholder: _vm.$t("Pleaseselect")
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.calculateGramWeight(
                                            _vm.addForm
                                          )
                                        }
                                      },
                                      model: {
                                        value: _vm.addForm.draftingMultiple,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.addForm,
                                            "draftingMultiple",
                                            $$v
                                          )
                                        },
                                        expression: "addForm.draftingMultiple"
                                      }
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "4.2", value: "4.2" }
                                      }),
                                      _c("el-option", {
                                        attrs: { label: "4.0", value: "4.0" }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("GRAM WEIGHT"),
                                    prop: "gramWeight"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "20%" },
                                    attrs: { type: "number" },
                                    model: {
                                      value: _vm.addForm.gramWeight,
                                      callback: function($$v) {
                                        _vm.$set(_vm.addForm, "gramWeight", $$v)
                                      },
                                      expression: "addForm.gramWeight"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("BASE PRODUCT PRICE"),
                                    prop: "baseProductPrice"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "20%" },
                                    attrs: { type: "number" },
                                    model: {
                                      value: _vm.addForm.baseProductPrice,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.addForm,
                                          "baseProductPrice",
                                          $$v
                                        )
                                      },
                                      expression: "addForm.baseProductPrice"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("ACTUAL PRODUCT PRICE"),
                                    prop: "actualProductPrice"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "20%" },
                                    attrs: { type: "number" },
                                    model: {
                                      value: _vm.addForm.actualProductPrice,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.addForm,
                                          "actualProductPrice",
                                          $$v
                                        )
                                      },
                                      expression: "addForm.actualProductPrice"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    "label-width": "150px",
                                    label: _vm.$t("ENABLE BATCH NO"),
                                    prop: "isBatch"
                                  }
                                },
                                [
                                  _c("el-switch", {
                                    attrs: {
                                      "active-value": 1,
                                      "inactive-value": 0,
                                      "active-text": _vm.$t("True"),
                                      "inactive-text": _vm.$t("False")
                                    },
                                    model: {
                                      value: _vm.addForm.enableBatch,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.addForm,
                                          "enableBatch",
                                          $$v
                                        )
                                      },
                                      expression: "addForm.enableBatch"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    "label-width": "150px",
                                    label: _vm.$t(
                                      "IS IT A STANDARD DISCOUNT VARIETY"
                                    ),
                                    prop: "isProduct"
                                  }
                                },
                                [
                                  _c("el-switch", {
                                    attrs: {
                                      "active-value": 1,
                                      "inactive-value": 0,
                                      "active-text": _vm.$t("True"),
                                      "inactive-text": _vm.$t("False")
                                    },
                                    model: {
                                      value: _vm.addForm.isStandard,
                                      callback: function($$v) {
                                        _vm.$set(_vm.addForm, "isStandard", $$v)
                                      },
                                      expression: "addForm.isStandard"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.categoryList
                        ? _c(
                            "el-collapse-item",
                            {
                              attrs: {
                                title: _vm.$t(
                                  "Material classification attribute"
                                ),
                                name: "2"
                              }
                            },
                            _vm._l(_vm.categoryList, function(item, index) {
                              return _c(
                                "el-col",
                                { key: item.id, attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        "label-width": "100px",
                                        label: item.name
                                      }
                                    },
                                    [
                                      item.type == 0
                                        ? _c("el-input-number", {
                                            attrs: {
                                              min: item.minValue,
                                              max: item.maxValue
                                            },
                                            model: {
                                              value:
                                                _vm.initAttributeList[index]
                                                  .value,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.initAttributeList[index],
                                                  "value",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "initAttributeList[index].value"
                                            }
                                          })
                                        : _vm._e(),
                                      item.type == 1
                                        ? _c(
                                            "el-select",
                                            {
                                              model: {
                                                value:
                                                  _vm.initAttributeList[index]
                                                    .value,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.initAttributeList[
                                                      index
                                                    ],
                                                    "value",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "initAttributeList[index].value"
                                              }
                                            },
                                            _vm._l(
                                              item.productPropertyValueResponseList ||
                                                [],
                                              function(i, index) {
                                                return _c("el-option", {
                                                  key: i.id,
                                                  attrs: {
                                                    label: i.name,
                                                    value: i.name
                                                  }
                                                })
                                              }
                                            ),
                                            1
                                          )
                                        : _vm._e(),
                                      item.type == 2
                                        ? _c("el-input", {
                                            model: {
                                              value:
                                                _vm.initAttributeList[index]
                                                  .value,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.initAttributeList[index],
                                                  "value",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "initAttributeList[index].value"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.addVisible = false
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("CANCEL")))]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.addSubmit }
                    },
                    [_vm._v(_vm._s(_vm.$t("Yes")))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.$t("Edit"),
                visible: _vm.editVisible,
                fullscreen: "",
                "append-to-body": true
              },
              on: {
                "update:visible": function($event) {
                  _vm.editVisible = $event
                }
              }
            },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.editForm,
                    rules: _vm.rules,
                    "label-width": "100px",
                    "label-position": "top"
                  }
                },
                [
                  _c(
                    "el-collapse",
                    {
                      on: { change: _vm.handleChange },
                      model: {
                        value: _vm.activeNames,
                        callback: function($$v) {
                          _vm.activeNames = $$v
                        },
                        expression: "activeNames"
                      }
                    },
                    [
                      _c(
                        "el-collapse-item",
                        { attrs: { title: _vm.$t("Basic Data"), name: "1" } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("MATERIAL CLASSIFICATION"),
                                    prop: "classification"
                                  }
                                },
                                [
                                  _c(
                                    "tree",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "PLEASE SELECT MATERIAL CLASSIFICATION"
                                        ),
                                        props: _vm.defaultProps,
                                        options: _vm.treeList
                                      },
                                      on: { getValue: _vm.value },
                                      model: {
                                        value: _vm.editForm.categoty,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.editForm,
                                            "categoty",
                                            $$v
                                          )
                                        },
                                        expression: "editForm.categoty"
                                      }
                                    },
                                    _vm._l(_vm.treeList, function(item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("MATERIAL NAME"),
                                    prop: "name"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.editForm.name,
                                      callback: function($$v) {
                                        _vm.$set(_vm.editForm, "name", $$v)
                                      },
                                      expression: "editForm.name"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("MATERIAL CODE"),
                                    prop: "code"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.editForm.code,
                                      callback: function($$v) {
                                        _vm.$set(_vm.editForm, "code", $$v)
                                      },
                                      expression: "editForm.code"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("SPECIFICATION AND MODEL"),
                                    prop: "models"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.editForm.models,
                                      callback: function($$v) {
                                        _vm.$set(_vm.editForm, "models", $$v)
                                      },
                                      expression: "editForm.models"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("UNIT OF MEASURE"),
                                    prop: "unit"
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        placeholder: _vm.$t("Pleaseselect")
                                      },
                                      model: {
                                        value: _vm.editForm.unitId,
                                        callback: function($$v) {
                                          _vm.$set(_vm.editForm, "unitId", $$v)
                                        },
                                        expression: "editForm.unitId"
                                      }
                                    },
                                    _vm._l(_vm.unitList, function(item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("INVENTORY TYPE"),
                                    prop: "type"
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      model: {
                                        value: _vm.editForm.type,
                                        callback: function($$v) {
                                          _vm.$set(_vm.editForm, "type", $$v)
                                        },
                                        expression: "editForm.type"
                                      }
                                    },
                                    _vm._l(_vm.typeList, function(item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("BAR CODE"),
                                    prop: "barCode"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.editForm.barCode,
                                      callback: function($$v) {
                                        _vm.$set(_vm.editForm, "barCode", $$v)
                                      },
                                      expression: "editForm.barCode"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("NUMBER OF BRANCHES"),
                                    prop: "branchNum"
                                  }
                                },
                                [
                                  _c("el-input-number", {
                                    model: {
                                      value: _vm.editForm.yarnCount,
                                      callback: function($$v) {
                                        _vm.$set(_vm.editForm, "yarnCount", $$v)
                                      },
                                      expression: "editForm.yarnCount"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("DRAFTING MULTIPLE"),
                                    prop: "draftingMultiple"
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "20%" },
                                      attrs: {
                                        placeholder: _vm.$t("Pleaseselect")
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.calculateGramWeight(
                                            _vm.editForm
                                          )
                                        }
                                      },
                                      model: {
                                        value: _vm.editForm.draftingMultiple,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.editForm,
                                            "draftingMultiple",
                                            $$v
                                          )
                                        },
                                        expression: "editForm.draftingMultiple"
                                      }
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "4.2", value: "4.2" }
                                      }),
                                      _c("el-option", {
                                        attrs: { label: "4.0", value: "4.0" }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("GRAM WEIGHT"),
                                    prop: "gramWeight"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "20%" },
                                    attrs: { type: "number" },
                                    model: {
                                      value: _vm.editForm.gramWeight,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editForm,
                                          "gramWeight",
                                          $$v
                                        )
                                      },
                                      expression: "editForm.gramWeight"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("BASE PRODUCT PRICE"),
                                    prop: "baseProductPrice"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "20%" },
                                    attrs: { type: "number" },
                                    model: {
                                      value: _vm.editForm.baseProductPrice,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editForm,
                                          "baseProductPrice",
                                          $$v
                                        )
                                      },
                                      expression: "editForm.baseProductPrice"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("ACTUAL PRODUCT PRICE"),
                                    prop: "actualProductPrice"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "20%" },
                                    attrs: { type: "number" },
                                    model: {
                                      value: _vm.editForm.actualProductPrice,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editForm,
                                          "actualProductPrice",
                                          $$v
                                        )
                                      },
                                      expression: "editForm.actualProductPrice"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    "label-width": "150px",
                                    label: _vm.$t("ENABLE BATCH NO"),
                                    prop: "isBatch"
                                  }
                                },
                                [
                                  _c("el-switch", {
                                    attrs: {
                                      "active-value": 1,
                                      "inactive-value": 0,
                                      "active-text": _vm.$t("True"),
                                      "inactive-text": _vm.$t("False")
                                    },
                                    model: {
                                      value: _vm.editForm.enableBatch,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editForm,
                                          "enableBatch",
                                          $$v
                                        )
                                      },
                                      expression: "editForm.enableBatch"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    "label-width": "150px",
                                    label: _vm.$t(
                                      "IS IT A STANDARD DISCOUNT VARIETY"
                                    ),
                                    prop: "isProduct"
                                  }
                                },
                                [
                                  _c("el-switch", {
                                    attrs: {
                                      "active-value": 1,
                                      "inactive-value": 0,
                                      "active-text": _vm.$t("True"),
                                      "inactive-text": _vm.$t("False")
                                    },
                                    model: {
                                      value: _vm.editForm.isStandard,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editForm,
                                          "isStandard",
                                          $$v
                                        )
                                      },
                                      expression: "editForm.isStandard"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-collapse-item",
                        {
                          attrs: {
                            title: _vm.$t("Material classification attribute"),
                            name: "3"
                          }
                        },
                        _vm._l(_vm.editClassificationList, function(item) {
                          return _c(
                            "el-col",
                            { key: item.id, attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    "label-width": "100px",
                                    label: item.name
                                  }
                                },
                                [
                                  item.type == 0
                                    ? _c("el-input-number", {
                                        attrs: {
                                          min: item.minValue,
                                          max: item.maxValue
                                        },
                                        model: {
                                          value: item.value,
                                          callback: function($$v) {
                                            _vm.$set(item, "value", $$v)
                                          },
                                          expression: "item.value"
                                        }
                                      })
                                    : _vm._e(),
                                  item.type == 1
                                    ? _c(
                                        "el-select",
                                        {
                                          model: {
                                            value: item.value,
                                            callback: function($$v) {
                                              _vm.$set(item, "value", $$v)
                                            },
                                            expression: "item.value"
                                          }
                                        },
                                        _vm._l(
                                          item.productPropertyValueResponseList ||
                                            [],
                                          function(i, index) {
                                            return _c("el-option", {
                                              key: i.id,
                                              attrs: {
                                                label: i.name,
                                                value: i.name
                                              }
                                            })
                                          }
                                        ),
                                        1
                                      )
                                    : _vm._e(),
                                  item.type == 2
                                    ? _c("el-input", {
                                        model: {
                                          value: item.value,
                                          callback: function($$v) {
                                            _vm.$set(item, "value", $$v)
                                          },
                                          expression: "item.value"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.editVisible = false
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("CANCEL")))]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.editSubmit }
                    },
                    [_vm._v(_vm._s(_vm.$t("Yes")))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "avue-crud",
            {
              ref: "crud",
              attrs: {
                option: _vm.tableOption,
                data: _vm.tableData,
                "table-loading": _vm.tableLoading,
                page: _vm.page
              },
              on: {
                "update:page": function($event) {
                  _vm.page = $event
                },
                "size-change": _vm.sizeChange,
                "current-change": _vm.currentChange,
                "selection-change": _vm.selectionChange,
                "refresh-change": _vm.refreshChange,
                "before-open": _vm.beforeOpen,
                "row-update": _vm.handleUpdate,
                "row-del": _vm.handleDel
              },
              scopedSlots: _vm._u([
                {
                  key: "status",
                  fn: function(scope) {
                    return [
                      _c("el-switch", {
                        attrs: { "inactive-value": 1, "active-value": 0 },
                        on: {
                          change: function($event) {
                            return _vm.change(scope.row)
                          }
                        },
                        model: {
                          value: scope.row.status,
                          callback: function($$v) {
                            _vm.$set(scope.row, "status", $$v)
                          },
                          expression: "scope.row.status"
                        }
                      })
                    ]
                  }
                },
                {
                  key: "menu",
                  fn: function(scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          staticClass: "none-border",
                          attrs: {
                            type: "text",
                            size: "small",
                            icon: "el-icon-edit"
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.editShow(scope.row)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("Edit")) +
                              "\n        "
                          )
                        ]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "none-border",
                          attrs: {
                            type: "text",
                            size: "small",
                            icon: "el-icon-delete"
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.handleDel(scope.row)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("Delete")) +
                              "\n        "
                          )
                        ]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "none-border",
                          attrs: {
                            type: "text",
                            size: "small",
                            icon: "el-icon-s-order"
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.clickProductPrice(scope.row)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("Price")) +
                              "\n        "
                          )
                        ]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "none-border",
                          attrs: {
                            type: "text",
                            size: "small",
                            icon: "el-icon-aim"
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.compareProductQuality(scope.row)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("Quality")) +
                              "\n        "
                          )
                        ]
                      )
                    ]
                  }
                },
                {
                  key: "menuForm",
                  fn: function(ref) {
                    var row = ref.row
                    var index = ref.index
                    var type = ref.type
                    return [
                      type == "add"
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-circle-plus-outline",
                                size: "small"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.$refs.crud.rowSave()
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("Hold")) +
                                  "\n        "
                              )
                            ]
                          )
                        : _vm._e(),
                      type == "edit"
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-circle-check",
                                size: "small"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.$refs.crud.rowUpdate()
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("Hold")) +
                                  "\n        "
                              )
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-circle-close",
                            size: "small"
                          },
                          on: {
                            click: function($event) {
                              return _vm.$refs.crud.closeDialog()
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("Cancel")) +
                              "\n        "
                          )
                        ]
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "template",
                {
                  staticStyle: { width: "calc(100% - 80px)" },
                  slot: "menuLeft"
                },
                [
                  _c(
                    "el-row",
                    { staticClass: "topFromSearch", attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        {
                          staticClass: "fromOutDiv",
                          attrs: { xs: 24, sm: 16, md: 16, lg: 19, xl: 20 }
                        },
                        [
                          _c(
                            "el-col",
                            {
                              staticClass: "formDiv",
                              attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("MATERIAL NAME OR CODE")) + ":"
                                )
                              ]),
                              _c("el-input", {
                                on: {
                                  focus: function($event) {
                                    return _vm.focusDiv($event)
                                  },
                                  blur: function($event) {
                                    return _vm.blurDiv($event)
                                  }
                                },
                                model: {
                                  value: _vm.nameOrCode,
                                  callback: function($$v) {
                                    _vm.nameOrCode = $$v
                                  },
                                  expression: "nameOrCode"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          staticStyle: {
                            "padding-top": "10px",
                            "text-align": "right",
                            "align-self": "flex-end"
                          },
                          attrs: { xs: 24, sm: 8, md: 8, lg: 5, xl: 4 }
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "iconfont tianjia",
                                size: "mini",
                                title: _vm.$t("New"),
                                plain: ""
                              },
                              on: { click: _vm.addShow }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("New")) + "\n            "
                              )
                            ]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                title: _vm.$t("Refresh"),
                                icon: "iconfont shuaxin",
                                size: "mini",
                                plain: ""
                              },
                              on: { click: _vm.refreshChange }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("Refresh")) +
                                  "\n            "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }